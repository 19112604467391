<template>
  <v-row>
    <v-col cols="12">
      <p>
        売上累計と累計コストを月別のグラフで表示しています。棒グラフをタップすると実数値を確認できます。
      </p>
      <p class="mb-0">
        「詳細を見る」をクリックすると月次の売上・コストの推移を確認でき、月の▽をタップすると売上・コストの内訳を確認できます。
      </p>
      <ul class="ml-4 mb-4">
        <li><router-link to="/sales-costs">月次推移を見る</router-link></li>
        <li>
          <router-link to="/sales-costs/daily">日時推移を見る</router-link>
        </li>
      </ul>
      <p>※ freeeの数値を同期しています（毎日自動更新）</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeSalesCostsModal"
};
</script>

<style scoped></style>
